import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';

import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { EnrollmentComponent } from './pages/enrollments/enrollment/enrollment.component';
import { AmplifyAuthenticatorModule } from '@aws-amplify/ui-angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AsyncPipe, CommonModule, JsonPipe } from '@angular/common';
import { RouterModule, RouterOutlet } from '@angular/router';
import { ToastrModule } from 'ngx-toastr';
import { NgbDateParserFormatter, NgbDatepickerModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from './core/shared.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatButtonModule } from '@angular/material/button';
import { QueriesService } from './services/queries.service';
import { DayJsDateFormatter } from './core/dayjs-date-formatter';
import { AuthService } from './services/auth.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { MatSidenavModule } from '@angular/material/sidenav';
import { ComponentsModule } from './components/components.module';
import { TermsGuard } from './auth/terms-guard';
import { TermsComponent } from './pages/terms/terms.component';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatChipsModule } from '@angular/material/chips';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { ClipboardModule } from '@angular/cdk/clipboard';



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    TermsComponent,
    AdminLayoutComponent
  ],

  imports: [
    BrowserModule,
    AppRoutingModule,
    AmplifyAuthenticatorModule,
    HttpClientModule,
    CommonModule,
    RouterOutlet,
    ToastrModule.forRoot(),

    BrowserAnimationsModule,
    NgbModule,
    NgbDatepickerModule,
    FormsModule,
    AsyncPipe,
    SharedModule,
    JsonPipe,
    ReactiveFormsModule,
    MatIconModule,
    MatStepperModule,
    MatButtonModule,
    MatAutocompleteModule,
    NgxSpinnerModule,
    ReactiveFormsModule,
    MatIconModule,
    ComponentsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatChipsModule,
    MatSidenavModule,
    ClipboardModule

  ],
  providers: [
    { provide: NgbDateParserFormatter, useValue: new DayJsDateFormatter() },
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { showError: true },
    },
    AuthService,
    QueriesService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    TermsGuard
  ],
  exports: [RouterModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
